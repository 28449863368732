body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  min-width: 100vw;
}

.div-log{
  background-color: white;
  width: 25vw;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
.div-header{
  background-color: #EEEEEE;
  height: 3rem;
  width: 100%;
  padding-left: 0.7rem;
  display: flex;
}
.div-container{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.tab-panel{
  width: 100%;
  height: 96vh;
  overflow-y: scroll;
}

.header-div-name{
  align-items: center;
  display: flex;
  font-size: larger;
  padding-right: 1vw;
}

.header-avatar:hover{
  cursor: pointer;
  background-color: #F1862C;
}

.header-menu{
  padding: 0.45vw;
}

.header-menu:hover{
  cursor: pointer;
  color: #F1862C;
}
.Menu-header{
  max-width: 100px;
  transition: all 0.5s ease-out;
  overflow: hidden;
  content-visibility: auto;
  display: inline;
}


.Menu-header[aria-expanded = 'true']{
  display: inline;
  content-visibility: auto;
  
}

.Menu-header[aria-expanded = 'false']{
  display: inline;
  max-width: 0px;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  content-visibility: hidden;
}

.img-login{
  height: 80vh;
  min-width: 44.53vw;
}