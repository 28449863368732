.groups-header{
    margin: -20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: linear-gradient(45deg, #f98727, #8707a3);
    margin-bottom: 20px;
    box-shadow: 0px 5px 5px #66747f;
}

.group-header{

    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 3vh;

}

.action-icon:hover{
    cursor: pointer;
    
    background-color: #d6d6d6;
    border-radius: 50px;
    padding: 5px;
}