.reports-body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    z-index: 1;
}

.reports-body-add-Report {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(5px);
    position: fixed;
    z-index: 2;
    margin-left: -20px;
}

.div-add-report-button {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding: 6%;
}

.div-container-add-report {
    
    display: flex;
    flex-wrap: wrap;
    min-height: 50%;
    justify-content: center;
    margin: auto;
    padding: 2%;
    box-shadow: 5px 5px 5px #666666;
    
    border: 0.2px solid #666666;
}

.reports-div-card {
    border-radius: 5px;
    width: 15vw;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: center;
    padding: 1%;
    box-shadow: 4px 1px 5px #66666638;
    flex-wrap: wrap;
    margin-bottom: 2%;
    margin-right: 2%;
    height: 30vh;

}

.reports-div-card:hover {

    transform: scale(1.1);
    transition: 0.5s;
    box-shadow: 8px 4px 10px #66666638;

}

.embed-container {
    height: 100%;
}

.reports-hr {
    margin-bottom: 2%;
}

.reports-reportes-container {
    display: flex;
    max-width: 100vw;
    flex-wrap: wrap;
    gap: 2rem;
}

.reports-div-svg {
    background-image: url('../../assets/reportSVG.svg');
    width: 7rem;
    height: 7rem;
}

.reports-div-svg:hover {
    cursor: pointer;
}

.reports-report-activo {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
}

.reportes-header {
    margin: -20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: linear-gradient(45deg, #f98727, #8707a3);
    margin-bottom: 20px;
    box-shadow: 0px 5px 5px #66747f;
}

.reporte-svg{
    height: 100px;
    width: 100px;
    background-image: url(../../assets/Icono-reporte.svg);
    background-color: rgb(238, 238, 238);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;


}
.reporte-svg-div{
    height: 50px;
    width: 50px;
   
}
.reporte-svg :hover{
    height: 100px;
    width: 100px;
    background-image: url(../../assets/Icono-reporte.svg);
    background-color: rgb(242, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    
    
   
    
}